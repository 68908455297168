<template>
    <div>
        <el-row>
            <el-col>
                <el-form :inline='true'>
                    <el-form-item label="关键字:">
                        <el-input v-model="filters.keyword" @input="(e) => (filters.keyword = utils.validForbid(e))" placeholder="项目编号/县（区）/关联合同编号" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="省份:">
                        <el-select v-model="filters.provinceCode" @change="changeSearchProvince" filterable clearable>
                            <el-option v-for="item in provinceSearchOptions" :key="item.id" :label="item.name"
                                :value="item.code"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="城市:">
                        <el-select v-model="filters.cityCode" @change="changeSearchCity" filterable clearable>
                            <el-option v-for="item in citySearchOptions" :key="item.id" :label="item.name"
                                :value="item.code"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="区域:">
                        <el-select v-model="filters.areaCode" filterable clearable>
                            <el-option v-for="item in regionSearchOptions" :key="item.id" :label="item.name"
                                :value="item.code"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="年:">
                        <el-date-picker v-model="filters.year" type="year" value-format="yyyy" placeholder="选择年">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="月:">
                        <el-select v-model="filters.month" clearable>
                            <el-option v-for="item in monthOptions" :key="item.value" :label="item.name"
                                :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="简报类型:">
                        <el-select v-model="filters.ddIdTimeType" clearable>
                            <el-option v-for="item in expertOptions" :key="item.id" :label="item.value"
                                :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="短信发送状态:">
                        <el-select v-model="filters.ddIdSendSmsStatus" clearable>
                            <el-option v-for="item in statusOptions" :key="item.value" :label="item.name"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <!-- 列表 -->
        <el-table :data="tableData" border style="" v-loading="listLoading" ref="multipleTable"
            @current-change='currentChange' :highlight-current-row='true'>
            <el-table-column type='index' label="序号" width='60' align="center" :index='indexMethod'></el-table-column>
            <el-table-column prop="areaName" label="县（区）" align="center"></el-table-column>
            <el-table-column prop="contractNo" label="关联合同编号" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="person" label="联系人" align="center" width="170">
                <template slot-scope="scope">
                    <el-button @click.native="showExternalPerson(scope.row)" type="text">查看</el-button>
                </template>
            </el-table-column>
            <el-table-column prop="ddIdTimeType" label="简报类型" align="center" width="100">
                <template slot-scope='scope'>
                    <el-tag v-if="scope.row.ddIdTimeType==150" type="danger">年报</el-tag>
                    <el-tag v-if="scope.row.ddIdTimeType==151" type="success">月报</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="cycleTime" label="月（年）份" align="center" width="130">
            </el-table-column>
            <el-table-column prop="createTime" label="生成时间" align="center" width="200"></el-table-column>
            <el-table-column prop="ddIdSendSmsStatus" label="短信发送状态" align="center" show-overflow-tooltip>
                <template slot-scope='scope'>
                    <el-tag v-if="scope.row.ddIdSendSmsStatus==152" type="danger">待发送</el-tag>
                    <el-tag v-if="scope.row.ddIdSendSmsStatus==153" type="success">已发送</el-tag>
                </template>
            </el-table-column>
        </el-table>
        <!--底部工具条-->
        <el-col :span="24" class="pageBar">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="pages.pageIndex" :page-sizes="pages.pageArr" :page-size="pages.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="pages.dataCount">
            </el-pagination>
        </el-col>
        <el-dialog title="联系人信息" :visible.sync="externalPersonVisible" v-model="externalPersonVisible"
            :close-on-click-modal="false">
            <el-table :data="dataExternalPersons" highlight-current-row v-loading="listLoading" style="width: 100%;">
                <el-table-column prop="name" label="姓名" width=""></el-table-column>
                <el-table-column prop="phone" label="手机号" width=""></el-table-column>
                <el-table-column prop="positionTypeName" label="人员岗位" width=""></el-table-column>
                <el-table-column prop="positionTagName" label="职位标签" width=""></el-table-column>
            </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="externalPersonVisible = false">关闭</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
    import util from "../../../util/date";
    import Toolbar from "../../components/Toolbar";
    import {
        getButtonList
    } from "../../promissionRouter";
    import {
        getGenWorkBriefList,
        getAreaDictionaryList,
        getContractContactUser,
        getAreaDictionaryByCode
    } from '@/api/api'
    export default {
        components: {
            Toolbar
        },
        data() {
            return {
                utils: '',
                buttonList: [],
                needData: undefined,
                filters: {
                    keyWord: '',
                    provinceCode: undefined,
                    cityCode: undefined,
                    regionCode: undefined,
                },
                tableData: [],
                listLoading: false,
                //关于分页的obj
                pages: {
                    pageSize: 20, //默认每页条数
                    pageArr: [10, 20, 30, 40], //默认每页条数选择
                    pageIndex: 1, //默认进入页
                    dataCount: 20, //默认总页数
                },
                externalPersonVisible: false,
                monthOptions: [{
                        name: "1月",
                        value: 1
                    },
                    {
                        name: "2月",
                        value: 2
                    },
                    {
                        name: "3月",
                        value: 3
                    },
                    {
                        name: "4月",
                        value: 4
                    },
                    {
                        name: "5月",
                        value: 5
                    },
                    {
                        name: "6月",
                        value: 6
                    },
                    {
                        name: "7月",
                        value: 7
                    },
                    {
                        name: "8月",
                        value: 8
                    },
                    {
                        name: "9月",
                        value: 9
                    },
                    {
                        name: "10月",
                        value: 10
                    },
                    {
                        name: "11月",
                        value: 11
                    },
                    {
                        name: "12月",
                        value: 12
                    }
                ],
                // options
                statusOptions: [{
                        name: '待发送',
                        value: 152
                    },
                    {
                        name: '已发送',
                        value: 153
                    }
                ],
                expertOptions: [{
                    "id": 150,
                    "namespace": "CommonService.OrgPermission.ExpertIdentity",
                    "key": 0,
                    "value": "年报",
                    "sort": 1,
                    "remark": ""
                }, {
                    "id": 151,
                    "namespace": "CommonService.OrgPermission.ExpertIdentity",
                    "key": 1,
                    "value": "月报",
                    "sort": 2,
                    "remark": ""
                }], //专家类型
                dataExternalPersons: [],
                provinceSearchOptions: [], //搜索 省
                citySearchOptions: [], //搜索 市
                regionSearchOptions: [], //搜索 区
                provinceOptions: [], //省
                cityOptions: [], //市
                regionOptions: [], //区
            }
        },
        watch: {

        },
        created() {
            this.utils = util
            let routers = window.localStorage.router ?
                JSON.parse(window.localStorage.router) : [];
            this.buttonList = getButtonList(this.$route.path, routers);
            this.getDataList();
            this.init();
        },
        methods: {
            callFunction(item) {
                if(item.func == "getDataList") {
                this.pages.pageIndex = 1
            }
                this[item.func].apply(this, item);
            },
            async init() {
                // 获取专家类型  获取所有省份 获取特长
                const initResult = await Promise.all([
                    // getByNamespace({ namespace: 'CommonService.OrgPermission.ExpertIdentity' }),
                    getAreaDictionaryList({
                        parentId: 1
                    })
                ])
                const [provinceRes] = initResult;
                // if (identityRes.data.success) {
                //     this.expertOptions = identityRes.data.response;
                // }
                if (provinceRes.data.success) {
                    this.provinceOptions = provinceRes.data.response;
                    this.provinceSearchOptions = provinceRes.data.response.reverse();
                }
            },
            //根据code  查询id
            getIdByCode(code) {
                getAreaDictionaryByCode({
                    code: code,
                    isEnable: true
                }).then(res => {
                    var result = res.data
                    if (result.success) {
                        return result.response.id;
                    }
                })
            },
            // 省切换
            changeSearchProvince(code) {
                this.filters.cityCode = undefined;
                this.filters.regionCode = undefined;
                this.citySearchOptions = [];
                this.regionSearchOptions = [];
                getAreaDictionaryByCode({
                    code: code,
                    isEnable: true
                }).then(res => {
                    var result = res.data
                    if (result.success) {
                        var id = result.response.id;
                        getAreaDictionaryList({
                            parentId: id
                        }).then(res => {
                            if (res.data.success) {
                                this.citySearchOptions = res.data.response.reverse();
                            }
                        })
                    }
                })
            },
            // 市切换
            changeSearchCity(code) {
                this.regionSearchOptions = [];
                this.filters.regionCode = undefined;
                getAreaDictionaryByCode({
                    code: code,
                    isEnable: true
                }).then(res => {
                    var result = res.data
                    if (result.success) {
                        var id = result.response.id;
                        getAreaDictionaryList({
                            parentId: id
                        }).then(res => {
                            if (res.data.success) {
                                this.regionSearchOptions = res.data.response.reverse();
                            }
                        })
                    }
                })
            },
            // 列表查询接口
            getDataList() {
                let params = {
                    keyword: this.filters.keyword,
                    pageSize: this.pages.pageSize,
                    pageIndex: this.pages.pageIndex,
                    provinceCode: this.filters.provinceCode,
                    cityCode: this.filters.cityCode,
                    areaCode: this.filters.areaCode,
                    year: this.filters.year,
                    month: this.filters.month,
                    ddIdTimeType: this.filters.ddIdTimeType,
                    ddIdSendSmsStatus: this.filters.ddIdSendSmsStatus,
                }
                this.listLoading = true
                getGenWorkBriefList(params).then(res => {
                    if (res.data.success) {
                        this.tableData = res.data.response.data;
                        this.pages.dataCount = res.data.response.dataCount;
                    }
                    this.listLoading = false
                })
            },
            createStateFilter(queryString) {
                return (state) => {
                    return (state.name.indexOf(queryString) === 0);
                };
            },
            handleSelect(item) {
                this.$set(this.addForm, "phone", item.phoneNumber);
                this.addForm.userInfoId = item.id;
                this.addForm.provinceCode = item.provinceCode;
                if (item.provinceCode) {
                    this.setDefaultAreaData(item.provinceCode, true, item.cityCode)
                    if (item.cityCode) {
                        this.setDefaultAreaData(item.cityCode, false, item.districtCode)
                    }
                }
            },
            setDefaultAreaData(code, isCity, subCode) {
                getAreaDictionaryByCode({
                    code: code,
                    isEnable: true
                }).then(res => {
                    var result = res.data
                    if (result.success) {
                        var id = result.response.id;
                        getAreaDictionaryList({
                            parentId: id
                        }).then(res => {
                            if (res.data.success) {
                                if (isCity) {
                                    this.cityOptions = res.data.response.reverse();
                                    this.addForm.cityCode = subCode;
                                } else {
                                    this.regionOptions = res.data.response.reverse();
                                    this.addForm.regionCode = subCode;
                                }
                            }
                        })
                    }
                })
            },
            // 点击table某一行
            currentChange(val) {
                this.needData = val.filePath
            },
            // 分页
            handleCurrentChange(val) {
                this.pages.pageIndex = val;
                this.getDataList();
            },
            handleSizeChange(val) {
                this.pages.pageSize = val;
                this.getDataList();
            },
            //查询
            getListData(){
              this.pages.pageIndex = 1 ;
              this.getDataList();
            },
            // 时间格式化
            formatCreateTime: function (row, column) {
                return !row.NoticeTime || row.NoticeTime == "" ?
                    "" :
                    util.formatDate.format(new Date(row.NoticeTime), "yyyy-MM-dd hh:mm");
            },
            showExternalPerson(row) {
                console.log(row);
                getContractContactUser({
                    contractId: row.contractId
                }).then((res) => {
                    this.externalPersonVisible = true;
                    this.dataExternalPersons = res.data.response;
                });
            },
            showAttachment() {
                if (this.needData) {
                    let a = document.createElement('a');
                    a.href = this.needData
                    document.body.append(a);
                    a.click();
                    a.remove();
                }
            },
            // 表格数据分页条数累计
            indexMethod(index) {
                var currentPage = this.pages.pageIndex
                var pageSize = this.pages.pageSize
                return (index + 1) + (currentPage - 1) * pageSize
            },
        }
    }
</script>

<style lang="stylus" scoped>

</style>